import React from "react";
import yourGif from '../Media/niggers_bg.png'; // Path to your GIF image
import "../Css/HomePage.css";



const HomePage = () => {
  
  return (
    <div className="box2 fadeIn">
        <img src={yourGif} alt="" />
    </div>
  );
};

export default HomePage;
