import React, { useState, useEffect } from "react";
import "../Css/VideoBG.css";
import HomePage from '../Components/HomePage';
import MainPage from '../Components/MainPage';

const VideoBG = ({ src }) => {
  const [muted, setMuted] = useState(true); // State to manage mute functionality
  const [showButton, setShowButton] = useState(true);
  const [showHomePage, setShowHomePage] = useState(false);
  const [showBlackBackground, setShowBlackBackground] = useState(false);
  const [showMainPage, setShowMainPage] = useState(false);
  
  const toggleMute = () => {
    setMuted(!muted); // Toggle between mute and unmute
    setShowButton(false);
    setShowBlackBackground(true);

    setTimeout(() => {
      setShowHomePage(true);
    }, 3000);

    setTimeout(() => {
      setShowBlackBackground(false);
      setShowHomePage(false);
      setShowMainPage(true); // Render MainPage after animations
    // }, 6000);
  }, 6000);

  };

  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  if (showMainPage) {
    return <MainPage />; // Render only MainPage when it's time
  }

  return (
    <div className="mob">
     {isMobile ? (
       <div className="box">
       {showButton && (
         <button onClick={toggleMute} className="mute-button">
           Enter $NIGGERS
         </button>
       )}
       {!showMainPage && ( // Conditionally render the video and black background
         <>
           <img src={src} alt="" className="mobileBg"/>
           {showBlackBackground && <div className="blackBackground" />}
         </>
       )}
       {showHomePage && <HomePage />}
     </div>

     ) : (
      <div className="box">
      {showButton && (
        <button onClick={toggleMute} className="mute-button">
          Enter $NIGGERS
        </button>
      )}
      {!showMainPage && ( // Conditionally render the video and black background
        <>
          <video autoPlay loop muted={muted}>
            <source src={src} type="video/mp4" />
          </video>
          {showBlackBackground && <div className="blackBackground" />}
        </>
      )}
      {showHomePage && <HomePage />}
    </div>
     )}
    </div>
  );
};

export default VideoBG;
