import React, { useState, useEffect } from "react";
import image1 from "../Media/1.jpg";
import image2 from "../Media/2.jpg";
import image3 from "../Media/3.jpg";
import image4 from "../Media/4.jpeg";
import image5 from "../Media/5.jpg";
import image6 from "../Media/6.jpeg";
import image7 from "../Media/7.jpg";
import image8 from "../Media/8.jpg";
import image9 from "../Media/9.jpg";
import image10 from "../Media/10.jpeg";
import image11 from "../Media/11.jpg";
import image12 from "../Media/12.jpg";
import image13 from "../Media/13.png";
import image14 from "../Media/14.jpg";
import image15 from "../Media/15.png";
import image16 from "../Media/16.jpg";
import image17 from "../Media/17.png";

import "../Css/MainPage.css";

const images = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
  image13,
  image14,
  image15,
  image16,
  image17,
];

function Nomics({ onClick }) {
  return (
    <div className="mediaQ">
      <div className="content">
        <div className="heroTextBox">
          <h1 className="heroText">$NIGGERNOMICS</h1>
        </div>

        <div className="nomicsContainer">
          <h2>Coming Soon!!!</h2>
        </div>

        <div className="heroTextBox">
          <a href="#" className="actionLinks" onClick={onClick}>
            GO BACK
          </a>
        </div>
      </div>

      <div className="footer">
        <h3>Celebrate Black History,buy $NIGGERS</h3>
        <h3>Copyright &copy;2024 $NIGGERS</h3>
      </div>
    </div>
  );
}

const MainPage = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1100);

  const [showNomics, setNomics] = useState(false);

  const goBack = () => {
    setNomics(false);
  };

  const renderTokenomics = () => {
    setNomics(true);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1100);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [currentImage, setCurrentImage] = useState(
    images[Math.floor(Math.random() * images.length)]
  );

  const changeImage = () => {
    const randomIndex = Math.floor(Math.random() * images.length);
    setCurrentImage(images[randomIndex]);
  };

  return (
    <div className="salesPage">
      {showNomics ? (
        <Nomics onClick={goBack} />
      ) : isMobile ? (
        // mobile section
        <div className="mediaQ">
          <div className="content">
            <div className="heroTextBox">
              <h1 className="heroText">$NIGGERS FOR SALE</h1>

              <div className="socialBox">
                <a href="#" className="actionLinks">
                  Twitter
                </a>
                <a href="#" className="actionLinks">
                  Telegram
                </a>

                <a href="#" className="actionLinks" onClick={renderTokenomics}>
                  Niggernomics
                </a>
              </div>
            </div>

            <div className="actionContainer">
              <div className="actionBox">
                <img src={currentImage} alt="" className="actionImage" />
              </div>

              <div className="mobileButtonBox">
                <a href="#" className="actionLinks" onClick={changeImage}>
                  Spawn $NIGGERS
                </a>

                <a href="#" className="actionLinks">
                  Buy $NIGGERS
                </a>
              </div>
            </div>
          </div>

          <div className="footer">
            <h3>Celebrate Black History,buy $NIGGERS</h3>
            <h3>Copyright &copy;2024 $NIGGERS</h3>
          </div>
        </div>
      ) : (
        // desktop section
        <div className="mediaQ">
          <div className="content">
            <div className="heroTextBox">
              <h1 className="heroText">$NIGGERS FOR SALE</h1>

              <div className="socialBox">
                <a href="#" className="actionLinks">
                  Twitter
                </a>
                <a href="#" className="actionLinks">
                  Telegram
                </a>

                <a href="#" className="actionLinks" onClick={renderTokenomics}>
                  Niggernomics
                </a>
              </div>
            </div>

            <div className="actionContainer">
              <a href="#" className="actionLinks" onClick={changeImage}>
                Spawn $NIGGERS
              </a>

              <div className="actionBox">
                <img src={currentImage} alt="" className="actionImage" />
              </div>

              <a href="#" className="actionLinks">
                Buy $NIGGERS
              </a>
            </div>
          </div>

          <div className="footer">
            <h3>Celebrate Black History,buy $NIGGERS</h3>
            <h3>Copyright &copy;2024 $NIGGERS</h3>
          </div>
        </div>
      )}
    </div>
  );
};

export default MainPage;
