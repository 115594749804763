import React, {useState,useEffect} from 'react';
import VideoBG from './Components/VideoBG';
import video from './Media/niggers.mp4';
import image from './Media/mobile_bg.jpg';

let myVideo = '';

function App() {

  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  isMobile ? myVideo = image : myVideo = video;


  return (
    <div className="App">
      <VideoBG src={myVideo} />
      {/* Your other components */}
    </div>
  );
}

export default App;